<template>
  <v-container
    fluid
    grid-list-{xs
    through
    xl}
  >
    <v-layout align-center justify-center>
      <div class="size">
        Select Your Settings
      </div>
    </v-layout>
    <divide-content :my-style="style" />
    <!--h2 class="heading-title">Setting</h2
  <v-card id="contact-form" class="contact-form" elevation="0"> -->
    <v-layout justify-start
              raw
              wrap
              style="display:block"
    >
      <!--  <div class="form-cls"> -->
      <form class="form mx-auto pa-3"
            @submit.prevent="onSubmit"
      >
        <!--  <h3 class="text-h3"> Select Your Role </h3>
                <v-divider class="mt-3 mb-3"> </v-divider> -->
        <v-layout
          align-center
          justify-space-around
          row
        >
          <v-flex xs6
                  sm6
                  md6
                  lg6
                  xl6
          >
            <v-img
              src="/assets/teacher.svg"
              width="auto "
              contain
            />
          </v-flex>
          <v-flex
            xs6
            sm6
            md6
            lg6
            xl6
          >
            <input v-model="register.role"
                   type="radio"
                   name="role"
                   value="teacher"
                   size="40"
                   checked
                   required
            >
            <label for="male" class="px-1 text-h1"><b> I am a Teacher </b></label>
          </v-flex>
        </v-layout>
        <v-divider class="mt-3 mb-3" />
        <v-layout
          align-center
          justify-space-around
          row
        >
          <v-flex xs6
                  sm6
                  md6
                  lg6
                  xl6
          >
            <v-img
              src="/assets/kids.svg"
              width="auto "
              contain
            />
          </v-flex>
          <v-flex xs6
                  sm6
                  md6
                  lg6
                  xl6
          >
            <input v-model="register.role"
                   type="radio"
                   name="role"
                   value="student"
                   size="40"
            >
            <label for="male" class="px-1 text-h1"><b> I am a Student </b></label>
          </v-flex>
        </v-layout>
        <v-divider class="mt-3 mb-3" />
        <v-btn
          color=" red lighten-2"
          dark
          class="form-ft"
          @click="onSubmit()"
        >
          Submit
        </v-btn>
        <!--<button class="button" @click="onSubmit()"> <b> Send </b></button>-->
      </form>
      <!--  </div> -->
    </v-layout>
  <!-- </v-card> -->
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import DivideContent from './Divider.vue'
import axios from 'axios';
    export default {
      name: 'Register',
      components: {
        DivideContent
      },
      props: {
          myStyle: {
              type: Object,
              default: () => {layout: "margin: 10px"}
          },},
      data () {
        return {
          style: {layout: "margin: 10px"},
          register: {role: ""},
          provider: null,
          token: null
        }
      },
      mounted () {
        //   console.log("mount::", this.$route.query.refresh_token)
          this.provider = this.$route.query.provider
          this.token = this.$route.query.refresh_token
      },
      computed: {
        ...mapGetters({
          topics: 'navigation/Register'
        })
      },
      created: function () {
        this.$store.commit('navigation/resetState');
        let showFind =true;
        this.$store.commit('navigation/replaceFind', showFind);
        let registerRole =true;
        this.$store.commit('navigation/registerRole', registerRole);
        let newPhys =false;
        this.$store.commit('navigation/replacePhys', newPhys);
        let titleStatus =false;
        this.$store.commit('navigation/titleStatus', titleStatus);
        let newPNP =false;
        this.$store.commit('navigation/replacePNP', newPNP);
      },
      methods: {
        async onSubmit(){
            // console.log("submit",this.register)
            if(this.register.role != ""){
                const response = await axios.post(process.env.VUE_APP_API+'auth/social/register', {
                        "provider": this.provider,
                        "user_role": this.register.role,
                        "refresh_token": this.token
                    })
                console.log(response, response.data.message)
                if(response.data.success) {
                    localStorage.setItem('token', response.data.data.access_token)
                    this.$notify({
                        group: 'notify',
                        type: 'success',
                        title: 'Success',
                        text: response.data.message
                    });
                    this.$router.push('/userDashboard')
                } else {
                    this.$notify({
                        group: 'notify',
                        type: 'error',
                        title: 'Error!!',
                        text: 'Login issue!!'
                    });
                }
            } else {
                // console.log("error:", this.register)
                this.$notify({
                    group: 'notify',
                    type: 'error',
                    title: 'Error!!',
                    text: 'Please select the role'
                });
            }

        },
      },

    }
</script>

<style scoped lang="scss">
@import '@/styles/screen-sizes.scss';
// @import '@/styles/screen-sizes.scss';
    .form-cls{
        margin: auto;
        width: 25%;
    }
    .form {
        border: none;
    }
    .lbl-mgn {
        margin-left: 3%;
    }
    .form-hd {
        margin-bottom: 5%;
    }
    .form-ft {
        margin-top: 5%;
    }
    .container.fluid.grid-list-\{xs.through.xl\} {
    text-align: center;
    line-height: 3;
    }
    h2.heading-title {
    margin-bottom: 20px;
}
form#role-form {
    border: 3px solid #929292;
    padding: 50px;
    border-radius: 80px 0px 80px 0px;
}

.contact-form {
	font-size: 16px;
	font-family: Lato;
	max-width: 500px;
	width: 100%;
	border-radius:7.5px;
	height:auto;
	margin: 0 auto;
}
.contact-form .alert{
font-size:18px;
text-align:center;
text-justify:center;
}
.contact-form .separator {
	border-bottom: solid 1px #ccc;
	margin-bottom: 15px;
}
.contact-form .form {
	display: flex;
	flex-direction: column;
	font-size: 16px;
	max-width: 400px;
	border-radius:8px;
	border:none;
	text-justify:center;
}

.contact-form .namebox {
	font-size: 16px;
	max-width: 450px;
	padding-right:10px;
}

.contact-form_title {
	color: #333;
	font-size: 32px;
	text-align:center;
	padding-top:20px;
}

.contact-form_subtitle {
	color: #333;
	font-size: 18px;
	text-justify:center;
}

.contact-form input[type="email"],
.contact-form input[type="text"],
.contact-form textarea {
	border: solid 1px #e8e8e8;
	font-family: 'Roboto', sans-serif;
	padding: 10px 7px;
	margin-bottom: 15px;
	outline: none;}

.contact-form textarea {
	resize: none;
}

.contact-form input[type="email"],
.contact-form input[type="text"],
.contact-form textarea,
.contact-form .button {
	border: solid 1px #333;
	font-size: 16px;
	border-radius: 5px;
}

.contact-form .button {
	background: #da552f;
	border: solid 2px #da552f;
	color: white;
	cursor: pointer;
	padding: 10px 50px;
	text-align: center;
	text-transform: uppercase;
	font-size: 16px;
	border-radius: 5px;
	text-decoration: none;
}

.contact-form .button:hover {
	background: #ea532a;
	border: solid 2px #ea532a;
}

</style>
